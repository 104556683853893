// const
import './partials/load-images.js'
import './libs/aos.js'
// for page
import './partials/aos-init.js'

function imageSize() {
    var textHeight = document.querySelector('.box-photo__text').offsetHeight;
    document.querySelector('.box-photo__photo').style.height = `${textHeight}px`;
}

addEventListener("resize", () => imageSize());

setTimeout(function() {
    imageSize();
  }, 1000);

  function toggleMobileMenu() {
    console.log('toggle');
    document.querySelector('.mobile-menu').classList.toggle('active');
  }

  document.querySelectorAll('.mobile-menu a, .hamburger, .mobile-menu__close-button').forEach((el) => {
    el.addEventListener('click', () => {
        toggleMobileMenu();
      });
  });


